import axios from "axios";
import useHelpers from "composables/useHelpers";
import { cloneDeep, merge } from "lodash";
import { storeToRefs } from "pinia";

const TIMEFRAME_TEMPLATE_ELEMENT_TO_USE_AS_KEY = "name";
const TIMEFRAME_TEMPLATE_DEFAULT_TIMELINE_ID = "DefaultTimeline";
export const TRANSITION_MEETING_DEFAULT_STATUS = "concept";
export const TRANSITION_MEETING_DEFAULT_STATUS_MESSAGE =
  "<b>Let op:</b> bij het opslaan van deze bespreking wordt de status op <b>concept</b> gezet.";

export const DEFAULT_TRANSITION_PLAN = {
  student_instruction: "",
  questions: [
    {
      id: null,
      question:
        "Wat zijn de belangrijkste redenen waarom je nu in de bespreekmarge valt?",
    },
    {
      id: null,
      question:
        "Wat heb je dit schooljaar al gedaan om je prestaties te verbeteren? Wat heeft wel of niet gewerkt?",
    },
    {
      id: null,
      question:
        "Welke concrete acties ga je ondernemen om ervoor te zorgen dat je volgend jaar succesvol bent?",
    },
    {
      id: null,
      question:
        "Hoe kunnen je mentor, docenten en ouders je hierbij helpen? Wat heb je van hen nodig?",
    },
  ],
};

export const DEFAULT_TRANSITION_MEETING = {
  id: null,
  name: "",
  school_id: null,
  school_year: null,
  status: TRANSITION_MEETING_DEFAULT_STATUS,
  message: "",
  schedules: {},
  teacher_scores: [],
  reports_shared: [],
  total_ratings: null,
  total_given_ratings: null,
  total_available_report_shares: null,
  timeframe_settings: {},
  meeting_type: "transition_meeting",
  student_ids: [],
  allow_share_feedback: null,
  allow_transition_plan: null,
  transition_plan: DEFAULT_TRANSITION_PLAN,
  timeline_selection: null,
  reports_shared_unique: null,
  total_reports_shared_unique: null,
};

export const TRANSITION_MEETING_BUTTON_ACTIONS = {
  copy({ source = null, target = null }) {
    this.timeframe_settings[target] = cloneDeep(
      this.timeframe_settings[source],
    );
  },
};

// Date operators
// When adding a new operator, make sure to add it to the DATE_OPERATORS_MESSAGE object as well.
export const DATE_OPERATORS = {
  after: (a, b) => a < b,
};

// {title} is a placeholder that will be replaced by the title of the group
const DATE_OPERATORS_MESSAGE = {
  after: "Deze datum moet na {title} komen",
};

export const operators = {
  "===": (a, b) => a === b,
  "!==": (a, b) => a !== b,
  ">": (a, b) => a > b,
  "<": (a, b) => a < b,
  ">=": (a, b) => a >= b,
  "<=": (a, b) => a <= b,
};

class TransitionMeetingTimelineSettings {
  _meetingStore;

  constructor(data = {}, meetingStore) {
    if (!meetingStore) {
      throw new Error(
        "Cannot create Transition meeting, meetingStore is required",
      );
    }

    // Define meetingStore as a non-enumerable property
    Object.defineProperty(this, "_meetingStore", {
      value: meetingStore,
      writable: false,
      configurable: false,
      enumerable: false,
    });

    this.initialize(data);
  }

  async initialize(data = {}) {
    // Make sure the meeting timeline templates are fetched
    await this._meetingStore.fetchMeetingTimelineTemplates();

    // Get the meeting timeline templates
    const { meetingTimelineTemplates } = storeToRefs(this._meetingStore);

    // Find the default timeline template
    const defaultTimelineTemplate = meetingTimelineTemplates.value.find(
      ({ id }) => id === TIMEFRAME_TEMPLATE_DEFAULT_TIMELINE_ID,
    );

    if (!defaultTimelineTemplate) {
      throw new Error("Could not find the default meeting timeline template");
    }

    // Set the default timeline template
    this.setTimeframeSettings(defaultTimelineTemplate, data);
  }

  setTimeframeSettings(meetingTimelineTemplate, data = {}) {
    const { groups = [] } = meetingTimelineTemplate ?? {};

    // Array of all elements in the groups, we can use this to map all the keys to the timeframe settings
    const allElements = groups.flatMap(
      ({ allow_multiple, group_key: name, elements = [] }) => {
        if (!allow_multiple) {
          return elements;
        }

        return {
          type: "group",
          name,
          elements,
        };
      },
    );

    allElements.forEach((element) => {
      const key = element[TIMEFRAME_TEMPLATE_ELEMENT_TO_USE_AS_KEY];
      const { type, defaultValue } = element;

      if (type !== "group") {
        return (this[key] ??= defaultValue);
      }

      this[key] ??= [
        element.elements.reduce((acc, { name, defaultValue }) => {
          acc[name] = defaultValue;

          return acc;
        }, {}),
      ];
    });

    merge(this, data);
  }
}

export class TransitionMeeting {
  id;
  name;
  school_id;
  school_year;
  status;
  message;
  schedules;
  teacher_scores;
  reports_shared;
  total_ratings;
  total_given_ratings;
  total_available_report_shares;
  timeframe_settings;
  meeting_type;
  student_ids;
  allow_transition_plan;
  transition_plan;
  allow_share_feedback;
  timeline_selection;
  reports_shared_unique;
  total_reports_shared_unique;
  _selected_timeline;
  _meetingStore;

  constructor(data = {}, meetingStore) {
    if (!meetingStore) {
      throw new Error(
        "Cannot create Transition meeting, meetingStore is required",
      );
    }

    this.id = data.id ?? DEFAULT_TRANSITION_MEETING.id;
    this.name = data.name ?? DEFAULT_TRANSITION_MEETING.name;
    this.school_id = data.school_id ?? DEFAULT_TRANSITION_MEETING.school_id;
    this.school_year =
      data.school_year ?? DEFAULT_TRANSITION_MEETING.school_year;
    this.status = data.status ?? DEFAULT_TRANSITION_MEETING.status;
    this.message = data.message ?? DEFAULT_TRANSITION_MEETING.message;
    this.schedules = data.schedules ?? DEFAULT_TRANSITION_MEETING.schedules;
    this.teacher_scores =
      data.teacher_scores ?? DEFAULT_TRANSITION_MEETING.teacher_scores;
    this.reports_shared =
        data.reports_shared ?? DEFAULT_TRANSITION_MEETING.reports_shared;
    this.reports_shared_unique =
        data.reports_shared_unique ?? DEFAULT_TRANSITION_MEETING.reports_shared_unique
    this.total_reports_shared_unique =
        data.total_reports_shared_unique ?? DEFAULT_TRANSITION_MEETING.total_reports_shared_unique
    this.total_ratings =
      data.total_ratings ?? DEFAULT_TRANSITION_MEETING.total_ratings;
    this.total_given_ratings =
      data.total_given_ratings ??
      DEFAULT_TRANSITION_MEETING.total_given_ratings;
    this.total_available_report_shares =
        data.total_available_report_shares ??
        DEFAULT_TRANSITION_MEETING.total_available_report_shares;

    this.timeframe_settings = new TransitionMeetingTimelineSettings(
      data.timeframe_settings,
      meetingStore,
    );

    this.meeting_type =
      data.meeting_type ?? DEFAULT_TRANSITION_MEETING.meeting_type;
    this.student_ids =
      data.student_ids ?? DEFAULT_TRANSITION_MEETING.student_ids;
    this.transition_plan =
      data.transition_plan ?? DEFAULT_TRANSITION_MEETING.transition_plan;

    // These values will trigger a timeline change
    this.allow_share_feedback =
      data.allow_share_feedback ??
      DEFAULT_TRANSITION_MEETING.allow_share_feedback;

    this.allow_transition_plan =
      data.allow_transition_plan ??
      DEFAULT_TRANSITION_MEETING.allow_transition_plan;

    this.timeline_selection =
      data.timeline_selection ?? DEFAULT_TRANSITION_MEETING.timeline_selection;

    // Define selected_timeline as a non-enumerable property
    Object.defineProperty(this, "_selected_timeline", {
      value: null,
      writable: true,
      configurable: false,
      enumerable: false,
    });

    // Define meetingStore as a non-enumerable property
    Object.defineProperty(this, "_meetingStore", {
      value: meetingStore,
      writable: false,
      configurable: false,
      enumerable: false,
    });

    this.timelineChanged(this.valuesForTimeframeSettings);
  }

  async timelineChanged() {
    const timeframeSettings = this.valuesForTimeframeSettings;

    // Make sure the meeting timeline templates are fetched
    await this._meetingStore.fetchMeetingTimelineTemplates();

    const { meetingTimelineTemplates } = storeToRefs(this._meetingStore);

    meetingTimelineTemplates.value.forEach((timeline) => {
      let { conditions } = timeline;

      const allConditionsMet = conditions.every(
        ({ field, operator, value }) =>
          operators[operator] &&
          operators[operator](timeframeSettings[field], value),
      );

      if (allConditionsMet) {
        this.selected_timeline = timeline;
      }
    });
  }

  setTimeframeSettings(timeline) {
    this.timeframe_settings.setTimeframeSettings(timeline);
  }

  get selected_timeline() {
    return this._selected_timeline;
  }

  set selected_timeline(timeline) {
    if (!timeline) {
      throw new Error("Timeline is required");
    }

    const { id: timelineId } = timeline;

    // If the selected timeline is the same as the new timeline, do nothing
    if (this.selected_timeline && this.selected_timeline.id === timelineId) {
      return;
    }

    this._selected_timeline = timeline;

    // Add the correct keys to the timeframe settings
    this.setTimeframeSettings(this.selected_timeline);
  }

  get valuesForTimeframeSettings() {
    const { allow_share_feedback, allow_transition_plan, timeline_selection } =
      this;

    return {
      allow_share_feedback,
      allow_transition_plan,
      timeline_selection,
    };
  }

  performAction(action, payload) {
    if (typeof TRANSITION_MEETING_BUTTON_ACTIONS[action] !== "function") {
      throw new Error(`Action ${action} is not defined`);
    }

    TRANSITION_MEETING_BUTTON_ACTIONS[action].call(this, payload);
  }

  get isNewMeeting() {
    return !!(this.id === null);
  }

  get canEdit() {
    return !!(this.isNewMeeting || this.status === "concept");
  }

  async store() {
    try {
      const { schoolId } = useHelpers();

      const { data } = await axios.post(
        `/api/school/${schoolId}/transition_meeting`,
        this,
      );

      merge(this, data);

      return this;
    } catch (error) {
      console.log("🚀 ~ TransitionMeeting ~ store ~ error:", error);
      throw new Error(error);
    }
  }

  async updatePlanning() {
    try {
      const {
        timeframe_settings,
        allow_transition_plan,
        allow_share_feedback,
        timeline_selection,
      } = this;

      const payload = {
        timeframe_settings,
        allow_transition_plan,
        allow_share_feedback,
        timeline_selection,
      };

      const { data } = await axios.post(
        `/api/school/${this.school_id}/transition_meeting/${this.id}/update-planning`,
        payload,
      );

      merge(this, data);

      return this;
    } catch (error) {
      throw new Error(error);
    }
  }

  operatorAndValueToErrorMessage({ operator, value }) {
    // Find the title of the group containing the element with the given value
    const { title = null } = this.selected_timeline.groups.find(
      ({ elements }) => elements.find(({ name }) => name === value),
    );

    if (!title || DATE_OPERATORS_MESSAGE[operator] == null) {
      // Here we return a generic message if the title or the operator is not found
      return "Dit veld is niet correct ingevuld";
    }

    // Here we return the message where the title is replaced by the title of the group
    return DATE_OPERATORS_MESSAGE[operator].replace("{title}", title);
  }
}
