<template>
  <MeetingModule :loading>
    <q-table
      class="sticky-header tw-mb-6"
      :loading
      flat
      bordered
      color="primary"
      :columns
      row-key="id"
      selection="multiple"
      :rows="formattedStudents"
      :selected="selected"
      :selected-rows-label="selectedRowsLabel"
      virtual-scroll
      :rows-per-page-label="`${mappedTerms().students} per pagina`"
      :rows-per-page-options="[0]"
      :no-data-label="`Geen ${mappedTerms().students} gevonden die voldoen aan: ${searchQuery}`"
      @row-click="
        (_, row) => isEditable && row.schedules_count > 0 && handleRowClick(row)
      "
      @selection="onSelection"
    >
      <template #top>
        <div class="tw-flex tw-flex-1 tw-items-center tw-gap-4">
          <q-input
            class="tw-flex-1"
            borderless
            v-model="searchQuery"
            :debounce="300"
            :label="`${mappedTerms().student} zoeken...`"
          >
            <template #append>
              <q-icon
                name="fas fa-magnifying-glass"
                size="xs"
                color="primary"
              />
            </template>
          </q-input>

          <c-input-select
            borderless
            class="tw-w-[250px]"
            :label="`Filter ${mappedTerms().mentorklas}`"
            v-model="selectedClass"
            :options="mentorClasses"
            use-chips
            multiple
            options-dense
          >
            <template
              v-slot:option="{ itemProps, opt, selected, toggleOption }"
            >
              <q-item v-bind="itemProps">
                <q-item-section side>
                  <c-checkbox
                    borderless
                    :model-value="selected"
                    @update:model-value="toggleOption(opt)"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label v-html="opt" />
                </q-item-section>
              </q-item>
            </template>
          </c-input-select>
        </div>
      </template>

      <template #header-selection="scope">
        <c-checkbox
          :disable="!isEditable"
          v-model="scope.selected"
          name="schedules"
        />
      </template>

      <template #body-selection="scope">
        <c-tooltip v-if="scope.row.schedules_count === 0"
          >Deze {{ mappedTerms().student }} heeft geen vakken dus kan niet
          gekoppeld worden.</c-tooltip
        >
        <c-checkbox
          :disable="!isEditable || scope.row.schedules_count === 0"
          :val="scope.row"
          :model-value="selected"
          name="schedules"
          @update:model-value="(val) => (selected = val)"
        />
      </template>
    </q-table>

    <q-tabs
      v-model="selectedTab"
      dense
      active-color="primary"
      indicator-color="primary"
      align="justify"
    >
      <q-tab
        v-for="[mentorClass, students] of selectedStudents"
        :name="mentorClass"
      >
        <div class="tw-flex tw-items-center tw-gap-2">
          <span class="tw-font-bold">{{ mentorClass }}</span> ({{
            students.length
          }})

          <c-button-icon
            :title="`Deselecteer alle leerlingen uit ${mentorClass}`"
            color="transparent"
            rounded
            text-color="negative"
            size="xs"
            icon="fas fa-xmark"
            :disable="!isEditable"
            @click="(evt) => handleDeleteClass(evt, mentorClass)"
          />
        </div>
      </q-tab>
    </q-tabs>

    <q-separator />

    <q-tab-panels class="!tw-bg-gray-50" v-model="selectedTab">
      <q-tab-panel
        class="tw-flex tw-flex-wrap !tw-p-2"
        v-for="[mentorClass, students] of selectedStudents"
        :name="mentorClass"
      >
        <q-chip
          class="tw-select-none"
          v-for="{ formattedName, id } in students"
          :removable="isEditable"
          :ripple="false"
          text-color="primary"
          @remove="isEditable && removeStudent(id)"
        >
          {{ formattedName }}
        </q-chip>
      </q-tab-panel>
    </q-tab-panels>
  </MeetingModule>
</template>

<script setup>
import { onMounted, ref, watchEffect, computed, watch } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";
import { storeToRefs } from "pinia";
import { useStudentStore } from "store/modules/studentStore";
import { useMeetingStore } from "store/modules/meetingStore";
import useHelpers from "composables/useHelpers.js";
const { mappedTerms } = useHelpers();

// Define the model
const model = defineModel({
  required: true,
  type: Object,
});
const isStepCompleted = defineModel("isStepCompleted");

// On model update
const modelUpdate = (value) => {
  isStepCompleted.value = value && value.length > 0;
};

const selectedRowsLabel = (value) => {
  return `${value} ${
    value && value > 1 ? mappedTerms().students : mappedTerms().student
  } geselecteerd`;
};

const handleRowClick = (row) => {
  if (row.schedules_count === 0) {
    return;
  }

  if (!selected.value.flatMap(({ id }) => id).includes(row.id)) {
    return selected.value.push(row);
  } else {
    selected.value = selected.value.filter(({ id }) => id !== row.id);
  }
};

// Student store
const studentStore = useStudentStore();
const { fetchStudents } = studentStore;
const { students, mentorClasses } = storeToRefs(studentStore);

// Meeting store
const meetingStore = useMeetingStore();
const { isEditable, initialStudents } = storeToRefs(meetingStore);

const removeStudent = (studentId) => {
  selected.value = selected.value.filter(({ id }) => id !== studentId);
};

// Computed
const formattedStudents = computed(() => {
  const query = searchQuery.value.toLowerCase().trim();
  let filteredStudents = students.value;

  // Filter out selected mentor classes
  if (selectedClass.value.length > 0) {
    filteredStudents = filteredStudents.filter(({ mentor_class }) =>
      selectedClass.value.includes(mentor_class),
    );
  }

  if (!query) {
    return filteredStudents;
  }

  return filteredStudents.filter(
    ({ formattedName, student_number, mentor_class }) => {
      return (
        formattedName.toLowerCase().trim().includes(query) ||
        student_number.includes(query) ||
        mentor_class.toLowerCase().trim().includes(query)
      );
    },
  );
});

const selectedStudents = computed(() => {
  const studentIdsSet = new Set(model.value.student_ids);
  const sorted = new Map();

  // Iterate over students once to filter and group them
  for (const student of students.value) {
    if (studentIdsSet.has(student.id)) {
      const { mentor_class } = student;

      if (!sorted.has(mentor_class)) {
        sorted.set(mentor_class, []);
      }

      sorted.get(mentor_class).push(student);
    }
  }

  // Sort the keys alphabetically
  const sortedKeys = Array.from(sorted.keys()).sort();

  // Create a new Map with sorted keys
  const sortedMap = new Map(sortedKeys.map((key) => [key, sorted.get(key)]));

  if (
    sortedMap.size &&
    (selectedTab.value === null || !sortedKeys.includes(selectedTab.value))
  ) {
    const [firstClass] = sortedMap.entries().next().value;
    selectedTab.value = firstClass;
  }

  // We need to use a map to preserve the sorted order
  return sortedMap;
});

// Refs
const loading = ref(true);
const searchQuery = ref("");
const selectedTab = ref(null);
const selected = ref([]);
const selectedClass = ref([]);

const columns = [
  {
    name: "student_number",
    label: "Leerlingnummer",
    field: "student_number",
    sortable: true,
    align: "left",
    style: "width: 25%",
  },
  {
    name: "formattedName",
    label: "Leerling naam",
    field: "formattedName",
    format: (val) => val,
    sortable: true,
    sort: (_a, _b, rowA, rowB) => {
      return rowA.last_name.localeCompare(rowB.last_name);
    },
    align: "left",
  },
  {
    name: "mentor_class",
    label: "Mentorklas",
    field: "mentor_class",
    sortable: true,
    align: "right",
    style: "width: 15%",
  },
];

const onSelection = ({ rows, added }) => {
  if (rows.length === 0) {
    return;
  }

  if (added) {
    const studentsWithSchedules = rows.filter(
      ({ schedules_count }) => schedules_count > 0,
    );

    selected.value = selected.value.concat(studentsWithSchedules);
  } else {
    selected.value = selected.value.filter(
      ({ id }) => !rows.some((row) => row.id === id),
    );
  }

  return selected.value;
};

// Watchers
watch(
  () => selected.value,
  (selected) => {
    model.value.student_ids = selected.flatMap(({ id }) => id);
  },
  { deep: true },
);

onMounted(async () => {
  await fetchStudents(true);

  loading.value = false;

  if (model.value?.student_ids == null) {
    model.value.student_ids = [];
  } else {
    // Add already selected students to selected list
    model.value.student_ids.forEach((studentId) => {
      const foundStudent = students.value.find(({ id }) => id == studentId);

      if (foundStudent) {
        selected.value.push(foundStudent);
      }
    });
  }

  initialStudents.value = model.value.student_ids;

  modelUpdate(model.value.student_ids);
});

watchEffect(() => {
  modelUpdate(model.value.student_ids);
});

const handleDeleteClass = (event, _mentor_class) => {
  event.stopPropagation();

  selectedTab.value = null;
  selected.value = selected.value.filter(
    ({ mentor_class }) => mentor_class !== _mentor_class,
  );
};
</script>

<style lang="scss">
// TODO: Create a wrapper for tabs and table components
// TODO: Move this styling to their respective components
.q-tabs {
  .q-tab__icon {
    @apply tw-rounded tw-bg-white tw-text-4xl;
  }
}

.q-table__container {
  .q-table__top {
    @apply tw-py-0;
  }

  table.q-table {
    @apply tw-border-t;
  }

  &.q-table--cell-separator {
    .q-table__top {
      @apply tw-border-b-0;
    }

    tbody {
      tr:nth-child(odd),
      tr:nth-child(odd) td {
        @apply tw-bg-gray-50 #{!important};
      }
    }
  }

  &.sticky-column.sticky-header {
    td:first-child {
      @apply tw-bg-white;
    }

    tr th {
      @apply tw-sticky tw-z-[2] tw-bg-white;
    }

    thead {
      tr:last-child th {
        @apply tw-z-[3];
        top: 48px;
      }

      tr:first-child th {
        @apply tw-z-[1];
        top: 0;
      }
    }

    tr:first-child th:first-child {
      @apply tw-z-[3];
    }

    td:first-child {
      @apply tw-z-[1];
    }

    td:first-child,
    th:first-child {
      @apply tw-sticky;
      left: 0;
    }

    tbody {
      scroll-margin-top: 48px;
    }
  }

  &.sticky-column {
    thead tr:first-child th:first-child {
      @apply tw-bg-white;
    }

    td:first-child {
      @apply tw-bg-white;
    }

    th:first-child,
    td:first-child {
      @apply tw-sticky tw-z-[1];
      left: 0;
    }
  }

  &.sticky-header {
    max-height: 600px;

    .q-table__top,
    .q-table__bottom,
    thead tr:first-child th {
      @apply tw-bg-white;
    }

    thead tr th {
      @apply tw-sticky tw-z-[3];
    }

    thead tr:first-child th {
      top: 0;
    }

    &.q-table--loading thead tr:last-child th {
      top: 48px;
    }

    tbody {
      scroll-margin-top: 48px;
    }
  }
}
</style>
