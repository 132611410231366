import useHelpers from "composables/useHelpers.js";
import { useModuleStore } from "store/modules/moduleStore.js";
import { storeToRefs } from "pinia";

const routes = [
  {
    name: "myLessongroups",
    path: "/my-lessongroups",
    redirect: () => {
      const moduleStore = useModuleStore();
      const { redirectToCorrectModule } = storeToRefs(moduleStore);
      return { name: redirectToCorrectModule.value.myLessongroupsRoute };
    },
    meta: {
      title: `Mijn lesgroepen`,
      role: "teacher",
      isMenuItem: true,
      settingRequired: ["studentMeeting", "learningObjectives", "portfolio"],
      menuOrder: 5,
      roleRequired: [
        "webmaster",
        "manager",
        "organizer",
        "teamleader",
        "decaan",
        "teacher",
      ],
      icon: "fa-users",
    },
    children: [
      {
        name: "myLessongroupsStudentMeetings",
        path: "meetings/:mentor?/:id?",
        component: () => import("pages/my-lessongroups/StudentMeetings.vue"),
        meta: {
          title: `Leerlingbespreking`,
          isSubmenuItem: true,
          settingRequired: ["studentMeeting"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
      },
      {
        name: "myLessongroupsTransitionMeetings",
        path: "transition-meetings",
        component: () => import("pages/my-lessongroups/TransitionMeetings.vue"),
        meta: {
          title: "Bevorderingsbespreking",
          isSubmenuItem: true,
          settingRequired: ["transitionMeeting", "studentMeeting"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
      },
      {
        path: "group-meetings",
        name: "myLessongroupsGroupMeetings",
        component: () => import("pages/my-lessongroups/GroupMeetings.vue"),
        meta: {
          title: "Groepsbespreking",
          isSubmenuItem: true,
          settingRequired: ["groupMeeting", "studentMeeting"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
      },
      {
        path: "portfolio",
        name: "myLessongroupsPortfolio",
        component: () =>
          import("pages/my-lessongroups/MyLessongroupsPortfolio.vue"),
        meta: {
          title: "Portfolio",
          isSubmenuItem: true,
          settingRequired: ["portfolio"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
      },
      {
        path: "learning-objectives",
        name: "myLessongroupsLearningObjectives",
        component: () => import("pages/my-lessongroups/LearningObjectives.vue"),
        meta: {
          title: "Leerdoelen",
          isSubmenuItem: true,
          settingRequired: ["learningObjectives"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
      },
    ],
  },
];

export default routes;
