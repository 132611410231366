<template>
  <li>
    <div>
      <span class="tw-font-bold">
        {{ group.title }}

        <span v-html="requiredLabel" />
      </span>

      <label
        class="tw-flex tw-items-center tw-gap-2 tw-tracking-wide tw-text-gray-700"
      >
        {{ group.label }}

        <c-tooltip-standalone
          v-if="group.tooltip"
          size="xs"
          anchor="top middle"
          self="bottom middle"
        >
          {{ group.tooltip }}
        </c-tooltip-standalone>
      </label>
    </div>

    <div class="tw-flex tw-flex-col tw-gap-4">
      <template
        v-if="!allowMultiple"
        v-for="{ component, element } in planningElements"
        :key="`${group.id}-${element.name}`"
      >
        <component
          :is="component"
          v-bind="element"
          v-model="model.timeframe_settings[element.name]"
          v-model:meeting="model"
        />
      </template>

      <template v-if="allowMultiple">
        <component
          :is="planningElements.component"
          v-model:meeting="model"
          v-model="model.timeframe_settings[planningElements.name]"
          :elements="planningElements.elements"
        />
      </template>
    </div>
  </li>
</template>

<script setup>
import { computed } from "vue";

// Dynamic planning elements
import DynamicPlanningDateTime from "components/meeting/modules/dynamic-planning-elements/DynamicPlanningDateTime.vue";
import DynamicPlanningBoolean from "components/meeting/modules/dynamic-planning-elements/DynamicPlanningBoolean.vue";
import DynamicPlanningButton from "components/meeting/modules/dynamic-planning-elements/DynamicPlanningButton.vue";
import DynamicPlanningMultipleDateTime from "components/meeting/modules/dynamic-planning-elements/DynamicPlanningMultipleDateTime.vue";

// Meeting model
const model = defineModel({
  required: true,
  type: Object,
});

// Props
const props = defineProps({
  group: {
    type: Object,
    required: true,
    default: () => ({}),
  },
});

const allowMultiple = computed(() => {
  const { group } = props;

  return group.allow_multiple;
});

// Return correct planning elements
const planningElements = computed(() => {
  const {
    group: { elements = [], group_key: name = null },
  } = props;

  if (allowMultiple.value) {
    return {
      component: DynamicPlanningMultipleDateTime,
      elements,
      name,
    };
  }

  return elements.map((element) => {
    switch (element.type) {
      case "datetime":
        return {
          component: DynamicPlanningDateTime,
          element,
        };
      case "boolean":
        return {
          component: DynamicPlanningBoolean,
          element,
        };
      case "button":
        return {
          component: DynamicPlanningButton,
          element,
        };
    }
  });
});

const requiredLabel = computed(() => {
  const { group } = props;

  return group.required ? "(verplicht)" : "(optioneel)";
});
</script>
