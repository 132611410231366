const REFETCH_TIME = parseInt(import.meta.env.VITE_REFETCH_DATA_TIME || 30000);

export const shouldFetch = (hasFetched) => {
  if (hasFetched === false || hasFetched === undefined) {
    return true;
  }

  if (hasFetched instanceof Date) {
    const currentTime = new Date().getTime();
    const fetchTime = hasFetched.getTime();
    const elapsedTime = currentTime - fetchTime;

    return elapsedTime >= REFETCH_TIME;
  }

  return false;
};
