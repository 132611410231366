<template>
  <MeetingModule>
    <loading-icon v-if="isFetching" />

    <div v-if="!isFetching" class="tw-flex tw-flex-col tw-gap-4">
      <div class="tw-flex tw-w-full tw-flex-col tw-gap-2 lg:tw-w-1/2">
        <c-radio-group
          :label="`Wil je de adviezen van de vakdocenten delen met de ${mappedTerms().student}?`"
          v-model="model.allow_share_feedback"
          :options="BOOLEAN_OPTIONS"
          inline
          :disable="!model.canEdit"
          @update:model-value="
            (value) => modelUpdate('allow_share_feedback', value)
          "
        />

        <c-banner v-if="!model.canEdit" type="warning">
          De bespreking is al gestart, je kunt de bovenstaande instelling niet
          meer aanpassen.
        </c-banner>
      </div>

      <div
        v-if="model.allow_share_feedback"
        class="tw-flex tw-w-full tw-flex-col tw-gap-2 lg:tw-w-1/2"
      >
        <c-radio-group
          :label="`Wil je de ${mappedTerms().student} een bevorderingsplan laten maken?`"
          v-model="model.allow_transition_plan"
          :options="BOOLEAN_OPTIONS"
          inline
          :disable="!model.canEdit"
          @update:model-value="
            (value) => modelUpdate('allow_transition_plan', value)
          "
          tooltip="Een bevorderingsplan bestaat uit maximaal 4 door de school zelf te formuleren vragen die een leerling moet beantwoorden. Hiermee kan de leerling laten zien waarom hij of zij over wil naar het volgende leerjaar. "
        />

        <c-banner v-if="!model.canEdit" type="warning">
          De bespreking is al gestart, je kunt de bovenstaande instelling niet
          meer aanpassen.
        </c-banner>
      </div>

      <div
        class="tw-flex tw-flex-col tw-gap-4"
        v-if="model.allow_transition_plan"
      >
        <div class="tw-flex tw-flex-col tw-gap-2">
          <c-label
            label="Instructie schrijven"
            tooltip="Dit bericht zien de leerlingen boven het in te vullen bevorderingsplan en in de uitnodiging die ze per e-mail ontvangen."
          />

          <c-input
            type="textarea"
            autogrow
            placeholder="Schrijf een instructie voor de leerling"
            v-model="model.transition_plan.student_instruction"
            :rules="[required, min(20)]"
          />
        </div>

        <c-banner
          v-if="model.transition_plan.questions.length === 0"
          class="tw-w-1/2"
          type="negative"
          >Voeg minimaal één vraag toe</c-banner
        >

        <div
          class="tw-flex tw-flex-col tw-gap-2"
          v-for="(question, index) in model.transition_plan.questions"
          :key="index"
        >
          <div class="tw-flex">
            <c-label class="tw-flex-1" :label="`Vraag ${index + 1}:`" />

            <c-button-icon
              :title="`Verwijder vraag ${index + 1}`"
              color="transparent"
              text-color="negative"
              size="12px"
              icon="fas fa-trash-alt"
              @click.stop="removeQuestion(question, index)"
            />
          </div>

          <c-input
            type="textarea"
            autogrow
            placeholder="Vul hier je vraag in"
            :debounce="defaultDebounceTime"
            :feedback="errors[question.id]"
            v-model="model.transition_plan.questions[index].question"
            @update:model-value="
              !model.isNewMeeting && updateQuestion(question, index)
            "
            :rules="[required, min(10)]"
          />
        </div>

        <c-banner
          v-if="isAddQuestionButtonDisabled"
          class="tw-w-full lg:tw-w-1/2"
          type="warning"
          >Je kunt maximaal 4 vragen toevoegen aan een
          bevorderingsplan.</c-banner
        >

        <c-button
          class="tw-self-start"
          label="Vraag toevoegen"
          icon="fa-duotone fa-plus-circle"
          :disable="isAddQuestionButtonDisabled"
          @click.stop="addQuestion"
        />
      </div>

      <div
        v-if="model.allow_transition_plan"
        class="tw-flex tw-flex-col tw-gap-2"
      >
        <c-radio-group
          :label="`Welke tijdlijn wil je toepassen op deze bespreking?`"
          v-model="model.timeline_selection"
          :options="FEEDBACK_PROCESS_OPTIONS"
          :disable="!model.canEdit"
          @update:model-value="
            (value) => modelUpdate('timeline_selection', value)
          "
          :tooltip="`Je kunt kiezen uit drie tijdlijnen, verschillend in volgorde waarin vakdocent en ${mappedTerms().student} de adviezen en het bevorderingsplan kunnen inzien.`"
        />

        <c-banner
          v-if="!model.canEdit"
          type="warning"
          class="tw-w-full lg:tw-w-1/2"
        >
          De bespreking is al gestart, je kunt de bovenstaande instelling niet
          meer aanpassen.
        </c-banner>
      </div>
    </div>
  </MeetingModule>
</template>

<script setup>
import { computed, onMounted, ref, useId } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";
import { useMeetingStore } from "store/modules/meetingStore";
import LoadingIcon from "components/loaders/LoadingIcon.vue";
import { mappedTerms, defaultDebounceTime } from "composables/useHelpers";
import { remove } from "lodash";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import CBanner from "components/wrappers/banner/CBanner.vue";
import { min, required } from "common/validationRules";

// Meeting model
const model = defineModel({
  required: true,
  type: Object,
});

const $q = useQuasar();

const isStepCompleted = defineModel("isStepCompleted");
const errors = ref({});

// Update model value
const stepsToValidate = computed(() => [
  {
    value: model.value.allow_share_feedback,
    validator: (value) => typeof value === "boolean",
  },
  ...(model.value.allow_share_feedback
    ? [
        {
          value: model.value.allow_transition_plan,
          validator: (value) => typeof value === "boolean",
        },
      ]
    : []),
  ...(model.value.allow_transition_plan
    ? [
        {
          value: model.value.transition_plan.questions,
          validator: (value) => Array.isArray(value) && value.length > 0,
        },
        {
          value: model.value.timeline_selection,
          validator: (value) => typeof value === "string",
        },
      ]
    : []),
]);

const modelUpdate = async (modelKey, value) => {
  if (modelKey === "allow_share_feedback" && value === false) {
    model.value.allow_transition_plan = null;
    model.value.timeline_selection = null;
  }

  if (modelKey === "allow_transition_plan" && value === false) {
    model.value.timeline_selection = null;
  }

  // This will set the correct timeline based on the changed input
  await model.value.timelineChanged();

  isStepCompleted.value = stepsToValidate.value.every(({ value, validator }) =>
    validator(value),
  );
};

// Meeting store
const meetingStore = useMeetingStore();
const {
  fetchMeetingTimelineTemplates,
  addTransitionMeetingQuestion,
  updateTransitionMeetingQuestion,
  deleteTransitionMeetingQuestion,
} = meetingStore;
const { meetingTimelineTemplates, meetingTimelineOptions } =
  storeToRefs(meetingStore);

// Model options
const BOOLEAN_OPTIONS = [
  { label: "Ja", value: true },
  { label: "Nee", value: false },
];

const FEEDBACK_PROCESS_OPTIONS = computed(() => {
  if (!meetingTimelineTemplates.value) {
    return [];
  }

  const foundOptions = meetingTimelineOptions.value.find(
    ({ model }) => model === "timeline_selection_options",
  );

  if (!foundOptions) {
    return [];
  }

  return foundOptions.value.map(({ label, value, tooltip }) => ({
    label,
    value,
    tooltip,
  }));
});

const MAX_QUESTION_COUNT = 4;

const isAddQuestionButtonDisabled = computed(
  () => model.value.transition_plan.questions.length >= MAX_QUESTION_COUNT,
);

const updateQuestion = async (question, index) => {
  let questionId = question.id;
  errors.value[questionId] = {};
  try {
    const { id } = question;
    if (id) {
      await updateTransitionMeetingQuestion({
        question: question,
        meetingId: model.value.id,
      });
    } else {
      const { data } = await addTransitionMeetingQuestion({
        question: question,
        meetingId: model.value.id,
      });
      model.value.transition_plan.questions[index] = data;
      questionId = data.id;
      errors.value[questionId] = {};
    }
    errors.value[questionId].type = "success";
    errors.value[questionId].message = "Gelukt";
  } catch (e) {
    console.log(e);
    errors.value[questionId].type = "error";
    errors.value[questionId].message = "Mislukt";
  } finally {
    setTimeout(() => {
      errors.value[questionId] = {};
    }, 3000);
  }
};

const addQuestion = () => {
  model.value.transition_plan.questions.push({});

  modelUpdate();
};

const removeQuestion = async (question, index) => {
  if (!model.value.isNewMeeting) {
    try {
      const { id } = question;
      await deleteTransitionMeetingQuestion({
        questionId: id,
        meetingId: model.value.id,
      });
      $q.notify({
        type: "positive",
        message: "Vraag verwijderd",
      });

      remove(model.value.transition_plan.questions, (_, i) => i === index);
    } catch (e) {
      $q.notify({
        type: "negative",
        message:
          e.message || "Er is iets mis gegaan, probeer het later opnieuw",
      });
    }
  } else {
    remove(model.value.transition_plan.questions, (_, i) => i === index);
  }

  modelUpdate();
};

const isFetching = ref(true);

onMounted(async () => {
  await fetchMeetingTimelineTemplates();

  isFetching.value = false;

  const models = [
    "timeline_selection",
    "allow_transition_plan",
    "allow_share_feedback",
  ];

  // Check the model onMounted
  models.forEach((_model) => modelUpdate(_model, model.value[_model]));
});
</script>
