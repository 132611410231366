<template>
  <div class="lg:tw-w-1/2">
    <c-button icon="fa-duotone fa-copy" :label @click="handleClick" />
  </div>
</template>

<script setup>
const meetingModel = defineModel("meeting", { required: true, type: Object });

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  source: {
    type: String,
    default: null,
  },
  target: {
    type: String,
    default: null,
  },
  action: {
    type: String,
    default: null,
  },
});

const handleClick = () => {
  const { action, source, target } = props;

  meetingModel.value.performAction(action, {
    source: source,
    target: target,
  });
};
</script>
