<template>
  <ul
    class="draggable-list"
    :class="{ 'is-dragging': isDragging && isDraggable }"
    v-auto-animate
  >
    <slot />
  </ul>
</template>

<script setup>
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";

// Emits
const emit = defineEmits(["update:order", "update:index"]);

// Define the model
const model = defineModel({
  required: true,
  type: Array,
  default: () => [],
});

const props = defineProps({
  dataType: {
    type: String,
    required: false,
  },
  dataIdKey: {
    type: String,
    default: () => "id",
    required: false,
  },
  isDraggable: {
    type: Boolean,
    default: () => false,
  },
  disable: {
    type: Boolean,
    default: () => false,
  },
});

// State
const isDragging = ref(false);

const orderChanged = ({ targetIndex, sourceIndex }) => {
  if (targetIndex < 0 || sourceIndex < 0) {
    return;
  }

  emit("update:order", {
    sourceIndex,
    targetIndex,
    model: model.value,
  });
};

// re-index
const reIndex = () => {
  emit("update:index", model.value);
};

const orderValues = computed(() => model.value.map((item) => item.order));

watch(
  () => orderValues.value,
  () => {
    reIndex();
  },
  { deep: true },
);

// Cleanup
let cleanup = () => {};

onMounted(() => {
  if (props.disable || !props.isDraggable) {
    return;
  }

  cleanup = monitorForElements({
    canMonitor: ({ source }) => {
      if (props.disable || !props.isDraggable) {
        return;
      }

      const { data } = source;

      return data?.dataType === props.dataType;
    },
    onDragStart: () => {
      if (props.disable || !props.isDraggable) {
        return;
      }

      isDragging.value = true;
    },
    onDrop: ({ location, source }) => {
      if (props.disable || !props.isDraggable) {
        return;
      }

      isDragging.value = false;

      const target = location.current.dropTargets[0];

      if (!target) {
        return;
      }

      const sourceData = source.data;
      const targetData = target.data;

      const sourceIndex = model.value.findIndex(
        (listItem) => listItem[props.dataIdKey] === sourceData.dataId,
      );

      const targetIndex = model.value.findIndex(
        (listItem) => listItem[props.dataIdKey] === targetData.dataId,
      );

      orderChanged({ targetIndex, sourceIndex });
    },
  });
});

onUnmounted(() => {
  cleanup();
});
</script>

<style lang="scss" scoped>
.draggable-list {
  @apply tw-border-2 tw-border-transparent tw-transition;

  &.is-dragging {
    @apply tw-border-indigo-200 tw-bg-indigo-50;
  }
}
</style>
