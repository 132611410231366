<template>
  <q-expansion-item
    v-bind="$attrs"
    :model-value="isExpanded"
    class="expansion-item"
    hide-expand-icon
    ref="expansionItemRef"
    @before-show="beforeShow"
    @show="updateModel(true)"
    @hide="updateModel(false)"
    :disable="!isExpandable"
  >
    <template #header="scope">
      <slot name="header" v-bind="scope || {}" />

      <q-item-section
        class="action-buttons tw-ml-2"
        :class="{ 'tw-opacity-60': !isExpandable }"
      >
        <!-- If we need this in multiple places we might need to add this on the CExpansionItem -->
        <c-button-icon
          class="tw-transition tw-duration-300"
          :class="{ 'tw-rotate-180': isExpanded }"
          color="transparent"
          size="14px"
          icon="fa-duotone fa-chevron-down"
          :title="isExpanded ? 'Inklappen' : 'Uitklappen'"
          @click.stop="expansionItemRef.toggle()"
        />

        <c-button-icon
          v-if="expandAll"
          class="tw-transition tw-duration-300"
          :class="{ 'tw-rotate-180': isExpandAll }"
          color="transparent"
          size="14px"
          icon="fa-duotone fa-chevrons-down"
          :title="isExpandAll ? 'Alles inklappen' : 'Alles uitklappen'"
          @click.stop="expandAllChildren(!isExpandAll)"
        />
      </q-item-section>
    </template>

    <template #default="scope">
      <slot name="default" v-bind="scope || {}" />
    </template>
  </q-expansion-item>
</template>

<script setup>
import { inject, onMounted, provide, ref, useAttrs, useTemplateRef } from "vue";

// Template ref
const expansionItemRef = useTemplateRef("expansionItemRef");

// Expose
defineExpose({
  expansionItemRef,
});

// Attributes
const attrs = useAttrs();

// Model
const isExpanded = ref(attrs["default-opened"] || false);
const isExpandAll = ref(false);

// Props
const props = defineProps({
  expandAll: {
    type: Boolean,
    default: false,
  },
  fetchFunction: {
    type: Function,
  },
  isExpandable: {
    type: Boolean,
    default: true,
  },
});

// Methods
const updateModel = (value) => {
  isExpanded.value = value;
};

const beforeShow = async () => {
  if (props.fetchFunction) {
    await props.fetchFunction();
  }
};

const expandAllChildren = async (value) => {
  if (props.fetchFunction) {
    await props.fetchFunction();
  }

  isExpandAll.value = value;
  updateModel(value);

  children.value.forEach((child) => {
    child.expandAllChildren(value);
  });
};

// Register itself in parent
const children = ref([]);
const registerChild = (child) => {
  children.value.push(child);
};

// If this ExpansionItem is inside another ExpansionItem, register it
const parentRegister = inject("registerChild", null);

// Provide expand function & registration
provide("registerChild", registerChild);
provide("expandAllChildren", expandAllChildren);

onMounted(() => {
  if (parentRegister) {
    parentRegister({ expandAllChildren });
  }
});
</script>

<style lang="scss">
.q-expansion-item {
  .q-item.q-item-type:has(.q-expansion-item__toggle-focus) {
    &:hover {
      @apply tw-bg-slate-200;
    }
  }

  // For now we misuse the disabled state to make the item non-clickable when there is no content
  .q-expansion-item__container .q-item.disabled {
    @apply tw-opacity-100 #{!important};
  }

  .q-item {
    &__section.action-buttons {
      @apply tw-flex tw-min-w-[auto] tw-flex-1 tw-flex-row tw-flex-nowrap tw-gap-2;
    }
  }
}
</style>
