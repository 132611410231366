<template>
  <div
    class="tw-grid tw-gap-4 tw-gap-y-2 lg:tw-w-1/2"
    :class="classes.container"
  >
    <div v-if="label" class="tw-col-span-full">
      {{ label }}
    </div>

    <div class="tw-flex" :class="classes.elements">
      <c-input-date-select
        v-model="_model.date"
        class="tw-flex-1"
        label="Datum"
        :rules="elementRules"
        :error
        @update:model-value="(value) => modelUpdate('date', value)"
        hide-bottom-space
      />
    </div>

    <div class="tw-flex" :class="classes.elements">
      <c-input-time-select
        v-model="_model.time"
        class="tw-flex-1"
        label="Tijdstip"
        :rules="elementRules"
        :error
        @update:model-value="(value) => modelUpdate('time', value)"
      />
    </div>

    <span
      v-if="multiple"
      class="tw-col-span-1 tw-flex tw-items-center tw-justify-center"
    >
      <template v-if="isFirstIndex"> tot </template>

      <c-button-icon
        v-if="isSecondIndex"
        title="Verwijderen"
        color="negative"
        size="md"
        icon="fas fa-trash-alt"
        @click.stop="() => emit('delete')"
      />
    </span>

    <span
      v-if="errors.length"
      class="tw-col-span-full tw-px-[12px] tw-text-[12px] tw-leading-[12px] tw-text-negative"
    >
      <pre>{{ errors[0] }}</pre>
    </span>
  </div>
</template>

<script setup>
import {
  fromDutchToIntlDate,
  fromIntlToDutchDateTime,
} from "common/dateHelpers";
import validationRules from "common/validationRules";
import { DATE_OPERATORS } from "models/meeting/transitionMeetingModel";
import { computed, onBeforeMount, ref, watch } from "vue";

// Meeting model
const meetingModel = defineModel("meeting", {
  required: true,
  type: Object,
});

// Timeframe settings model
const model = defineModel({ required: true });

// Local model
const _model = ref(null);

// Emits
const emit = defineEmits(["delete"]);

// Props
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  rules: {
    type: Array,
    default: () => [],
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: 0,
  },
  tooltip: {
    type: String,
  },
});

// improved readability for checking index
const isFirstIndex = computed(() => {
  return props.index === 0;
});

const isSecondIndex = computed(() => {
  return props.index === 1;
});

const classes = computed(() => {
  return {
    container: [...(props.multiple ? ["tw-grid-cols-11"] : ["tw-grid-cols-2"])],
    elements: [...(props.multiple ? ["tw-col-span-5"] : ["tw-col-span-1"])],
  };
});

// Rules bound to the element
// e.g. required, minLength, maxLength
const elementRules = computed(() => {
  const { rules } = props;

  if (!rules) {
    return [];
  }

  return rules
    .filter((rule) => validationRules[rule])
    .map((rule) => validationRules[rule]);
});

// Return rules that are related to the date operators
// e.g. after, before
// This is used to check if the date is valid
const operatorRules = computed(() => {
  const { rules } = props;

  return rules.filter((rule) => {
    const opratorFromRule = rule.toString().split(":")[0];

    return DATE_OPERATORS[opratorFromRule];
  });
});

const errors = computed(() => {
  if (model.value == null) {
    return [];
  }

  const errorList = operatorRules.value.reduce((acc, rule) => {
    // Get the operator and the values from the rule string
    // e.g. after:timeframe_settings.opens_at_date|timeframe_settings.opens_at_time
    // e.g. before:timeframe_settings.archived_at_date|timeframe_settings.archived_at_time
    const [operatorRule, valuesToCheck] = rule.toString().split(":");

    // e.g. opens_at_date|opens_at_time
    // e.g. archived_at_date|archived_at_time
    const valuesToCheckWithoutPrefix = valuesToCheck
      .split("|")
      .map((value) => value.replace("timeframe_settings.", ""));

    const values = valuesToCheckWithoutPrefix.filter((value) => {
      // If the value is not set, it is considered valid
      if (meetingModel.value.timeframe_settings[value] == null) {
        return false;
      }

      const isValid = DATE_OPERATORS[operatorRule](
        meetingModel.value.timeframe_settings[value],
        model.value,
      );

      if (!isValid) {
        return value;
      }
    });

    return values.length > 0
      ? acc.concat(values.map((value) => ({ operator: operatorRule, value })))
      : acc;
  }, []);

  return errorList.map((error) =>
    meetingModel.value.operatorAndValueToErrorMessage(error),
  );
});

const error = computed(() => errors.value.length > 0);

watch(
  () => model.value,
  (value) => {
    updateModelValue(value);
  },
  { deep: true },
);

const updateModelValue = (value) => {
  if (!value) {
    return;
  }

  const { date = null, time = null } = fromIntlToDutchDateTime(value);

  Object.assign(_model.value, { date, time });
};

// Update model
const modelUpdate = () => {
  const { date = null, time = null } = _model.value;

  model.value = fromDutchToIntlDate(date, time) || null;
};

onBeforeMount(() => {
  if (_model.value === null) {
    _model.value = {};
  }

  if (model.value !== null) {
    const dateObj =
      model.value instanceof Date ? model.value : new Date(model.value);

    if (!isNaN(dateObj)) {
      model.value = dateObj;

      updateModelValue(dateObj);
    }
  }

  _model.value.date ||= null;
  _model.value.time ||= null;
});
</script>
