<template>
  <div
    class="drop-indicator"
    :class="[orientationStyles[edgeToOrientationMap[edge]], [edgeStyles[edge]]]"
    :style="{
      '--line-thickness': `${strokeSize}px`,
      '--line-offset': `calc(-0.5 * (${gap} + ${strokeSize}px))`,
      '--terminal-size': `${terminalSize}px`,
      '--terminal-radius': `${terminalSize / 2}px`,
      '--negative-terminal-size': `-${terminalSize}px`,
      '--offset-terminal': `${offsetToAlignTerminalWithLine}px`,
    }"
  />
</template>

<script setup>
const props = defineProps({
  edge: {
    type: String,
    required: true,
  },
  gap: {
    type: String,
    required: true,
    default: () => "8px",
  },
});

const edgeToOrientationMap = {
  top: "horizontal",
  bottom: "horizontal",
  left: "vertical",
  right: "vertical",
};

const orientationStyles = {
  horizontal:
    "tw-h-[--line-thickness] tw-left-[--terminal-radius] tw-right-0 before:tw-left-[--negative-terminal-size]",
  vertical:
    "tw-w-[--line-thickness] tw-top-[--terminal-radius] tw-bottom-0 before:tw-top-[--negative-terminal-size]",
};

const edgeStyles = {
  top: "tw-top-[--line-offset] before:tw-top-[--offset-terminal]",
  right: "tw-right-[--line-offset] before:tw-right-[--offset-terminal]",
  bottom: "tw-bottom-[--line-offset] before:tw-bottom-[--offset-terminal]",
  left: "tw-left-[--line-offset] before:tw-left-[--offset-terminal]",
};

const strokeSize = 2;
const terminalSize = 8;
const offsetToAlignTerminalWithLine = (strokeSize - terminalSize) / 2;
</script>

<style lang="scss">
.drop-indicator {
  @apply tw-pointer-events-none tw-absolute tw-z-10 tw-box-border tw-bg-indigo-400;

  &::before {
    @apply tw-absolute tw-h-[--terminal-size] tw-w-[--terminal-size] tw-rounded-full tw-border-[length:--line-thickness] tw-border-solid tw-border-indigo-400 tw-content-[''];
  }
}
</style>
