<template>
  <section class="form-group">
    <h2 class="tw-flex tw-items-center tw-gap-2" v-if="title">
      {{ title }}

      <c-tooltip-standalone v-if="tooltip" @click.self="openTooltip" size="xs"
        ><span v-html="tooltip" />
      </c-tooltip-standalone>
    </h2>
    <h3 v-if="subTitle">{{ subTitle }}</h3>
    <p v-if="description">{{ description }}</p>

    <slot />
  </section>
</template>

<script setup>
const props = defineProps({
  title: String,
  subTitle: String,
  description: String,
  tooltip: String,
  tooltipLink: String,
});

const openTooltip = () => {
  const { tooltipLink } = props;

  if (!tooltipLink) {
    return;
  }

  return window.open(tooltipLink, "_blank");
};
</script>

<style lang="scss">
.form-group {
  @apply tw-mb-4;
}

.meeting-wrapper {
  .form-group {
    @apply tw-mb-12;
  }
}
</style>
