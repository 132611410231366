<template>
  <MeetingModule>
    <div class="tw-flex tw-flex-col tw-gap-8">
      <c-banner v-if="!selectedTimeline" type="negative">
        Er is iets misgegaan bij het ophalen van de tijdlijn.
      </c-banner>

      <ol v-if="selectedTimeline" class="tw-flex tw-flex-col tw-gap-6">
        <dynamic-planning-section
          v-for="group in selectedTimeline.groups"
          :key="group.id"
          class="tw-flex tw-flex-col tw-gap-2"
          :group
          v-model="model"
        />
      </ol>

      <c-banner
        v-if="selectedTimeline && statusMessage"
        class="tw-max-w-full md:tw-max-w-[75%]"
        type="warning"
      >
        <span v-html="statusMessage" />
      </c-banner>
    </div>
  </MeetingModule>
</template>

<script setup>
import { computed } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";
import DynamicPlanningSection from "components/meeting/modules/dynamic-planning-elements/DynamicPlanningSection.vue";
import {
  operators,
  TRANSITION_MEETING_DEFAULT_STATUS,
  TRANSITION_MEETING_DEFAULT_STATUS_MESSAGE,
} from "models/meeting/transitionMeetingModel";

// Meeting object
const model = defineModel({
  required: true,
  type: Object,
});

// Timeline
const selectedTimeline = computed(() => {
  return model.value.selected_timeline;
});

const statusMessage = computed(() => {
  model.value.status = TRANSITION_MEETING_DEFAULT_STATUS;
  let meetingStatusMessage = TRANSITION_MEETING_DEFAULT_STATUS_MESSAGE;

  const { status_conditions } = selectedTimeline.value;

  if (!status_conditions || !status_conditions.length) {
    return meetingStatusMessage;
  }

  status_conditions.forEach(({ field, operator, value, status, message }) => {
    let modelValue = model.value.timeframe_settings[field];

    if (!modelValue) {
      return;
    }

    if (!(modelValue instanceof Date)) {
      modelValue = new Date(modelValue);
    }

    // Currenty we always use the current date
    value = new Date();

    if (operators[operator] == null) {
      throw new Error(`Invalid operator: ${operator}`);
    }

    if (operators[operator](modelValue, value)) {
      model.value.status = status;
      meetingStatusMessage = message;
    }
  });

  return meetingStatusMessage;
});
</script>
