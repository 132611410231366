<template>
  <div class="tw-flex tw-flex-col tw-gap-2">
    <c-label v-if="label" :label :tooltip />

    <q-option-group
      v-bind="$attrs"
      :size
      type="radio"
      :class="classes"
      :disable
      v-model="model"
    >
      <template v-slot:label="option">
        <div class="tw-flex tw-w-full tw-items-center tw-gap-4 tw-p-1">
          {{ option.label }}

          <c-tooltip-standalone
            v-if="option.tooltip"
            class="tw-ml-auto"
            size="xs"
            anchor="top middle"
            self="bottom middle"
            >{{ option.tooltip }}
          </c-tooltip-standalone>
        </div>
      </template>
    </q-option-group>
  </div>
</template>

<script setup>
import { computed } from "vue";

const model = defineModel();

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  size: {
    type: String,
    default: "xs",
  },
  borderless: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
  },
  label: {
    type: String,
  },
  disable: {
    type: Boolean,
    default: false,
  },
});

const classes = computed(() => {
  return [
    ...(props.borderless ? ["borderless"] : []),
    ...(props.inline ? ["inline"] : []),
  ];
});
</script>

<style lang="scss">
.q-option-group {
  @apply tw-flex;

  &:not([class*="tw-gap"]) {
    @apply tw-gap-4;
  }

  &:not(.q-option-group--inline) {
    @apply tw-flex-col;
  }

  &.q-option-group--inline {
    @apply tw-m-0;

    > div {
      @apply tw-m-0 tw-flex-1;
    }
  }

  div.q-radio {
    display: flex !important;
    border: 1px solid $grey;
    border-radius: $generic-border-radius;
    transition: border $generic-hover-transition;
    padding-right: 4px;

    &:hover {
      border: 1px solid $primary;
    }
  }
}

div.q-option-group {
  &.borderless {
    div.q-radio {
      border: none;
    }
  }
}
</style>
