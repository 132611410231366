<template>
  <div class="tw-flex tw-flex-col tw-gap-4">
    <div
      v-for="(_, modelIndex) in model"
      :key="useId()"
      class="tw-flex tw-gap-4"
    >
      <dynamic-planning-date-time
        v-for="({ label, name, rules }, elementIndex) in elements"
        :key="useId()"
        :label
        :rules
        multiple
        :index="elementIndex"
        v-model="model[modelIndex][name]"
        v-model:meeting="meetingModel"
        @delete="() => removeDateTime(modelIndex)"
      />
    </div>

    <c-button
      class="tw-self-start"
      icon="fa-duotone fa-plus-circle"
      label="Datum toevoegen"
      @click="addDateTime"
    />
  </div>
</template>

<script setup>
import DynamicPlanningDateTime from "components/meeting/modules/dynamic-planning-elements/DynamicPlanningDateTime.vue";
import CModal from "components/wrappers/modal/CModal.vue";
import { remove } from "lodash";
import { useQuasar } from "quasar";
import { useId } from "vue";

const $q = useQuasar();

// Meeting model
const meetingModel = defineModel("meeting", {
  required: true,
  type: Object,
});

const model = defineModel({ required: true });

const props = defineProps({
  elements: {
    type: Array,
    required: true,
    default: () => [],
  },
});

// const canAddDateTime = computed(() => {
//   return model.value.every((value) => {
//     return Object.values(value).every((val) => val !== null);
//   });
// });

const removeDateTime = (index) => {
  if (!model.value?.[index]) {
    return;
  }

  const hasValues = Object.values(model.value[index]).some(
    (val) => val !== null,
  );

  const doRemove = () => {
    remove(model.value, (_, i) => i === index);
  };

  if (!hasValues) {
    return doRemove();
  }

  $q.dialog({
    component: CModal,
    componentProps: {
      title: "Verwijderen",
      message: "Weet je zeker dat je dit item wil verwijderen?",
      cancel: true,
      primaryLabel: "Item verwijderen",
      type: "negative",
    },
  }).onOk(async () => {
    doRemove();
  });
};

const addDateTime = () => {
  const { elements = [] } = props;

  model.value.push(
    elements.reduce((acc, { name, defaultValue }) => {
      acc[name] = defaultValue;

      return acc;
    }, {}),
  );
};
</script>
