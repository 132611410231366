<template>
  <q-field
    class="c-field"
    borderless
    v-model="model"
    bg-color="transparent"
    v-bind="$attrs"
    tag="div"
    :class="{
      'c-field--readonly': readonly && !isEditing,
    }"
  >
    <div class="tw-relative tw-block tw-w-full">
      <c-button
        v-if="readonly && editableWhileReadonly"
        @click="handleEditClick"
        size="sm"
        class="c-field--edit-button tw-absolute tw-right-0 tw-top-0 tw-bg-green-500"
        :outline="!isEditing"
        :icon="isEditing ? 'fa-solid fa-check' : 'fa-solid fa-pencil'"
        :text-color="isEditing ? 'white' : 'primary'"
      />
      <div class="c-field__container">
        <slot />
      </div>
    </div>

    <c-tooltip v-if="readonly && editableWhileReadonly && !isEditing"
      >Klik op het potloodje om dit onderdeel te wijzigen.</c-tooltip
    >
  </q-field>
</template>

<script setup>
import { ref } from "vue";

const model = defineModel();
const isEditing = ref(false);
const emit = defineEmits(["save"]);

defineProps({
  readonly: {
    type: Boolean,
    default: false,
  },
  editableWhileReadonly: {
    type: Boolean,
    default: false,
  },
});

const handleEditClick = () => {
  isEditing.value = !isEditing.value;

  if (!isEditing.value) {
    emit("save");
  }
};
</script>

<style lang="scss">
.c-field {
  &.c-field--readonly {
    @apply tw-cursor-not-allowed;

    .c-field__container {
      @apply tw-pointer-events-none  tw-cursor-alias tw-opacity-50;
    }
  }
}

.q-field {
  &.q-field--error {
    .q-editor__content,
    .q-checkbox:not(.disabled),
    .q-input {
      @apply tw-border-red-700 tw-bg-red-50/50;
    }

    .q-field__control.text-negative .q-field__control-container {
      @apply tw-text-primary;
    }

    // Editor icons normal color (B, I, U etc)
    .q-editor {
      @apply tw-text-primary;
    }

    // Hide the icon that shows up when there is an error
    .q-field__append {
      @apply tw-hidden;
    }
  }
}
</style>
