<template>
  <q-linear-progress :size :value :color rounded>
    <div class="flex flex-center tw-inset-0 tw-z-10">
      <q-badge
        color="transparent"
        text-color="primary"
        :label="computedLabel"
      />
    </div>
  </q-linear-progress>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  size: {
    type: String,
    default: () => "20px",
  },
  value: {
    type: Number,
    default: () => 0,
  },
  color: {
    type: String,
    default: () => "positive",
  },
  label: {
    type: [String, null],
    default: () => null,
  },
  completed: {
    type: Number,
    default: () => 0,
  },
  total: {
    type: Number,
    default: () => 0,
  },
});

const computedLabel = computed(() => {
  if (props.label) {
    return props.label;
  }

  return `${props.completed} van ${props.total} ingeleverd`;
});
</script>

<style lang="scss">
.q-linear-progress {
  @apply tw-flex tw-items-center tw-justify-center;
}
</style>
