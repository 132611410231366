import { defineStore } from "pinia";
import { reactive } from "vue";

/**
 * Notify Store
 *
 * This store manages the state and actions related to notifications.
 */
export const useNotifyStore = defineStore("notify", () => {
  // Refs
  const activeNotifications = reactive({});
  const timers = reactive({});

  /**
   * Sets a notification.
   * @param {Object} params - The parameters for the notification.
   * @param {string} params.key - The key for the notification.
   * @param {string} params.message - The message for the notification.
   * @param {number} params.duration - The duration of the notification, -1 for infinite.
   * @param {'success'|'error'} params.type - The type of the notification.
   */
  const setNotification = ({ key, message, type, duration = 5000 }) => {
    if (!key) {
      const error = new Error();
      console.error(
        "Error key is required in setErrorMessage is required.",
        error.stack,
      );
      return;
    }

    // If a notification already exists for this key and it's not the same type or message, remove it
    if (
      activeNotifications[key]?.type !== type &&
      activeNotifications[key]?.message !== message
    ) {
      delete activeNotifications[key];
    }

    // If a notification already exists for this key, increment its counter
    if (activeNotifications[key]) {
      activeNotifications[key].counter++;
    } else {
      // Otherwise, add the notification to the activeNotifications with a counter of 1
      activeNotifications[key] = { message, type, counter: 1 };
    }

    // If a timer already exists for this key, clear it
    if (timers[key]) {
      clearTimeout(timers[key]);
    }

    // Set a new timer to remove the notification after 5 seconds
    timers[key] = setTimeout(
      () => {
        delete activeNotifications[key];
        delete timers[key];
      },
      duration === -1 ? 100000000 : duration,
    );
  };

  return {
    setNotification,
    activeNotifications,
  };
});
