<template>
  <div class="tw-flex tw-flex-col tw-gap-2">
    <label
      class="tw-flex tw-items-center tw-gap-2 tw-tracking-wide tw-text-gray-700"
    >
      {{ label }}

      <c-tooltip-standalone
        v-if="tooltip"
        size="xs"
        anchor="top middle"
        self="bottom middle"
      >
        {{ tooltip }}
      </c-tooltip-standalone>
    </label>

    <div class="tw-grid tw-gap-4 lg:tw-w-1/2">
      <c-radio-group
        v-model="_model"
        :name="name"
        :options="BOOLEAN_OPTIONS"
        inline
        @update:model-value="modelUpdate"
      />
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";

const model = defineModel({ required: true });

// Local model
const _model = ref(null);

// Props
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  defaultValue: {
    type: [String, Number, Boolean, Date, null],
    default: null,
  },
  tooltip: {
    type: String,
  },
});

// Constants
const BOOLEAN_OPTIONS = [
  { value: true, label: "Ja" },
  { value: false, label: "Nee" },
];

// Update model
const modelUpdate = (value) => {
  model.value = _model.value;
};

onBeforeMount(() => {
  const { defaultValue } = props;

  _model.value = model.value != null ? model.value : defaultValue;
});
</script>
