<template>
  <c-button class="q-btn--menu" v-bind="$attrs" :icon-right="buttonIcon">
    <c-menu v-model="showMenu" v-bind="menuOptions" @hide="emit('hide')">
      <slot />
    </c-menu>
  </c-button>
</template>

<script setup>
import { computed, ref } from "vue";

const emit = defineEmits(["hide"]);

const showMenu = ref(false);

const buttonIcon = computed(() => {
  return [
    "fa-duotone",
    "fa-chevron-down",
    ...(showMenu.value ? ["fa-flip-vertical"] : []),
  ].join(" ");
});

defineProps({
  menuOptions: {
    type: Object,
    required: false,
  },
});
</script>

<style lang="scss">
button.q-btn.q-btn--menu {
  .q-btn__content {
    > .q-icon {
      @apply tw-text-[14px] tw-transition tw-duration-300;
    }
  }
}
</style>
