<template>
  <li ref="listItemRef" :data-draggable="isDraggable" class="tw-relative">
    <slot />

    <c-drop-indicator
      v-if="
        isDraggable && state.type === 'is-dragging-over' && state.closestEdge
      "
      :edge="state.closestEdge"
      :gap
    />
  </li>
</template>

<script setup>
import { onMounted, onUnmounted, ref, useTemplateRef } from "vue";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import {
  attachClosestEdge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";

// Props
const props = defineProps({
  listOrder: {
    type: Number,
    required: false,
  },
  dataType: {
    type: String,
    required: false,
  },
  dataId: {
    type: [String, Number],
    required: false,
  },
  gap: {
    type: String,
    default: () => ".5rem",
  },
  isDraggable: {
    type: Boolean,
    default: () => false,
  },
  disable: {
    type: Boolean,
    default: () => false,
  },
});

// Template refs
const listItemRef = useTemplateRef("listItemRef");

// State
const state = ref({ type: "idle" });

// Cleanup
let cleanup = () => {};

const getData = () => ({
  dataType: props.dataType,
  dataId: props.dataId,
});

onMounted(() => {
  if (props.disable || !props.isDraggable) {
    return;
  }

  cleanup = combine(
    draggable({
      element: listItemRef.value,
      getInitialData: () => getData(),
      onDragStart: () => {
        state.value = { type: "is-dragging" };
      },
      onDrop: () => {
        state.value = { type: "idle" };
      },
      canDrag: () => props.isDraggable && !props.disable,
    }),
    dropTargetForElements({
      element: listItemRef.value,
      canDrop: ({ source }) => {
        if (source.element === listItemRef.value) {
          return false;
        }

        return source.data?.dataType === props.dataType;
      },
      getData: ({ input }) =>
        attachClosestEdge(getData(), {
          element: listItemRef.value,
          input,
          allowedEdges: ["top", "bottom"],
        }),
      getIsSticky: () => true,
      onDragEnter: ({ self }) => {
        const closestEdge = extractClosestEdge(self.data);

        state.value = { type: "is-dragging-over", closestEdge };
      },
      onDrag({ self }) {
        const closestEdge = extractClosestEdge(self.data);

        // Only need to update react state if nothing has changed.
        if (
          state.value.type !== "is-dragging-over" ||
          state.value.closestEdge !== closestEdge
        ) {
          state.value = { type: "is-dragging-over", closestEdge };
        }
      },
      onDragLeave: () => {
        state.value = { type: "idle" };
      },
      onDrop: () => {
        state.value = { type: "idle" };
      },
    }),
  );
});

onUnmounted(() => {
  cleanup();
});
</script>
