<template>
  <StatusBar />
  <component v-if="initializing === false" :is="layout">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </component>
</template>

<script setup>
import axios from "axios";
import { computed, onBeforeMount, onMounted, onUpdated, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useMenuStore } from "store/modules/menuStore.js";
import { getCookie, generateMenuTitle } from "composables/useHelpers";

// Authorization Store
const autorizationStore = useAuthorizationStore();
const { isImpersonating } = autorizationStore;

// menuStore
const menuStore = useMenuStore();
const {
  fetchUnreadNotificationsWithInterval: useFetchNotificationsWithInterval,
  fetchUnfinishedTasksWithInterval: useFetchUnfinishedTasksWithInterval,
} = menuStore;
import StatusBar from "components/layout/StatusBar.vue";
import { useAuthorizationStore } from "store/modules/authorizationStore.js";

// Router
const router = useRouter();
const route = useRoute();

// Refs
const initializing = ref(true);

const layout = computed(() => {
  return route?.meta?.layout || "MainLayout";
});

const fetchNotificationsWithInterval = async () => {
  let token = localStorage.getItem("user_token");
  let ssoEnabled = localStorage.getItem("validate_2fa");
  const schoolId = localStorage.getItem("school_id");

  // How we validate now in the app. Rewrite this. -> Line added for readability
  const isAuthenticated =
    schoolId !== null && token !== null && ssoEnabled === null;

  if (isAuthenticated) {
    useFetchNotificationsWithInterval();
    useFetchUnfinishedTasksWithInterval();
  }
};

onUpdated(async () => {
  await fetchNotificationsWithInterval();
});

onMounted(async () => {
  await router.isReady();
  analytics();

  initializing.value = false;
});

onBeforeMount(async () => {
  let user = localStorage.getItem("user");

  // TODO: Herschrijven
  if (user) {
    user = JSON.parse(user);
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("user_token");
    axios.defaults.headers.common["TwoFactor"] = getCookie(
      "TwoFactor" + user.id,
    );

    if (isImpersonating) {
      axios.defaults.headers.common["Is-Impersonating"] = "true";
    } else {
      delete axios.defaults.headers.common["Is-Impersonating"];
    }
  }
});

const analytics = () => {
  let school_name = localStorage.getItem("school_name");
  let role = "onbekende rol";
  let scriptExists = document.getElementById("gtag-script");
  if (!scriptExists) {
    let analyticsScript = document.createElement("script");
    analyticsScript.id = "gtag-script";
    analyticsScript.setAttribute(
      "src",
      "https://www.googletagmanager.com/gtag/js?id=G-J1WBE7PNP7",
    );
    // analyticsScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-89827046-2')
    document.head.appendChild(analyticsScript);
  }

  let datalayerExists = document.getElementById("datalayer-script");
  if (datalayerExists) {
    let removeEl = document.getElementById("datalayer-script");
    removeEl.parentNode.removeChild(removeEl);
  }

  let user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    role = "Onbekende rol2";

    if (user.role.includes("webmaster")) {
      role = "webmaster";
    }

    if (user.role.includes("manager")) {
      role = "manager";
    }

    if (user.role.includes("organizer")) {
      role = "organizer";
    }

    if (user.role.includes("teamleader")) {
      role = "teamleader";
    }

    if (user.role.includes("teacher")) {
      role = "teacher";
    }

    if (user.role.includes("student")) {
      role = "student";
    }

    let scriptTag = document.createElement("script");
    scriptTag.id = "datalayer-script";
    let gaScript = document.createTextNode(
      "  window.dataLayer = window.dataLayer || [];\n" +
        "function gtag(){dataLayer.push(arguments);}\n" +
        "gtag('js', new Date());\n" +
        "gtag('config', 'G-J1WBE7PNP7', {'page_title': '" +
        generateMenuTitle(route) +
        "', 'page_path': '" +
        route.path +
        "'});\n" +
        "gtag('set', 'user_properties', { 'Rol vd gebruiker' : '" +
        role +
        "','School naam' : '" +
        school_name +
        "'});\n",
    );
    scriptTag.appendChild(gaScript);
    document.head.appendChild(scriptTag);
  }
};
</script>
